body {

background-color: #252526;
background-image: url("https://www.transparenttextures.com/patterns/asfalt-dark.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

@media only screen and (max-width: 600px) {
  .navBarSimple {
    color: white;
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .navBarSimple li {
    display: inline-block;
    font-size: 1.25em;
    padding: 5px;
  }
}

@media only screen and (min-width: 601px) {
  .navBarSimple {
    color: white;
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .navBarSimple li {
    display: inline-block;
    font-size: 1.5em;
    padding: 20px;
  }

}

.navBarSimple {
  color: white;
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.navBarSimple li {
  display: inline-block;
  font-size: 1.25em;
  padding: 5px;
}

.nav-item {
  color: white;
}


.section-header {
  font-family: 'Montserrat', sans-serif;
  color: #eb2586;
  font-size: 2em;
  font-weight: 400;
  text-shadow: 0 0 1px #eb2586;
}

.aboutSlug {
  color: white;

}

.ServiceTitle {
  font-family: 'Montserrat', sans-serif;
  color: #eb2586;
  font-size: 2em;
  text-shadow: 0 0 1px #eb2586; 
  font-weight: 400;
}

hr {
  border-bottom: 1px solid #eb2586;
  box-shadow: 0 20px 20px -20px #eb2586;
}

.aboutbrieftext {
  color: white;
}

.borderShadow {
  -webkit-box-shadow: 0 8px 6px -6px #eb2586;
	-moz-box-shadow: 0 8px 6px -6px #eb2586;
  box-shadow: 0 8px 6px -6px #eb2586;
  text-decoration: none;
}

.borderShadow:hover {
  text-decoration: none;
}
.activeLink {
  font-weight: "bold";
  color: #eb2586;
  -webkit-box-shadow: 0 8px 6px -6px black;
	-moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  text-decoration: none;
}

.socialIcons {
  font-size: 2em;
}

.partnerLogoBg {
  background: #d4d4d4;
  
}